import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { Helmet } from 'react-helmet'

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: [
            "Lora",
            "Montserrat Alternates",
            "-apple-system",
            "BlinkMacSystemFont",
            "Montserrat",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});

const ReduxWrapper = ({ element }) => (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Helmet htmlAttributes={{ lang: 'en-US' }} />
            {element}
        </Provider>
    </ThemeProvider>

);

export default ReduxWrapper;