module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vann Holmes' Photography","short_name":"Vann Holmes' Website","description":"The website provides a captivating and comprehensive overview of Vann Holmes' portfolio, enabling visitors to appreciate their artistic vision, skills, and accomplishments.","lang":"en","display":"standalone","start_url":"/","icon":"src/assets/favicon/favicon.png","icons":[{"src":"src/assets/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicon/apple-touch-icon.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c0bbd83fb3248d6afb699698fbf6fc09"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
