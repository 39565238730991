import { createSlice } from '@reduxjs/toolkit'

export const photoSlice = createSlice({
    name: 'photos',
    initialState: {
        value: {
            displayedImages: [],
            individualDescriptions: {}
        },
    },
    reducers: {
        setPhoto: (state, action) => {
            state.value.displayedImages.length = 0;
            state.value.individualDescriptions = {};
            state.value.displayedImages = action.payload.displayedImages;
            state.value.individualDescriptions = action.payload.individualDescriptions;
        },
    },
})

export const { setPhoto } = photoSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPhoto = (state) => state.photos.value

export default photoSlice.reducer
